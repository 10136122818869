import httpUtil from "@/utils/httpUtil";

/** 获取客户标签 */
export function fetchClient(params,type) {
	return httpUtil.post("/companyCustomerLable/getTree", params, type)
}

/** 删除客户*/
export function delClient(params,type) {
	return httpUtil.post("/customer/deleteCustomer", params, type)
}


/** 保存客户标签 */
export function addLableList(params,type) {
	return httpUtil.post("/companyCustomerLable/addLableList", params, type)
}


/** 获取客户列表数据 */
export function getCustomerPC(params,type) {
	return httpUtil.get("/customer/list", params, type)
}

/** 获取成员列表数据 */
export function getCompanyStaffList(params,type) {
	return httpUtil.post("/businessCard/getCompanyStaffList", params, type)
}

/** 转移客户 */
export function moveCustomer(params,type) {
	return httpUtil.post("/userTask/moveCustomer", params, type)
}


/** 抢为独占客户 */
export function updBatchCustomerState(params,type) {
	return httpUtil.post("/customer/updBatchCustomerState", params, type)
}

/** 更新客户信息 */
export function updCustomer(params,type) {
	return httpUtil.post("/customer/updCustomer", params, type)
}

/** 新增客户信息 */
export function manualAdd(params,type) {
	return httpUtil.post("/customer/manualAdd", params, type)
}

/** 获取客户信息 */
export function getCustomerInfo(params,type) {
	return httpUtil.post("/customer/getCustomerInfo", params, type)
}


/** 获取客户信息 */
export function getFollowList(params,type) {
	return httpUtil.post("/followRecord/getFollowList", params, type)
}


/** 添加跟进记录 */
export function addFollow(params,type) {
	return httpUtil.post("/followRecord/addFollow", params, type)
}

/** 添加成交记录 */
export function inputDealMoney(params,type) {
	return httpUtil.post("/customer/inputDealMoney", params, type)
}


/** 获取成交记录 */
export function getInputDealListt(params,type) {
	return httpUtil.post("/inputDealMoney/getInputDealList", params, type)
}


/** 设置广告图 */
export function insertCompanyPhoto(params,type) {
	return httpUtil.post("/companyPhoto/insertCompanyPhoto", params, type)
}



/** 获取广告图 */
export function selectCompanyPhoto(params,type) {
	return httpUtil.post("/companyPhoto/selectCompanyPhoto", params, type)
}












